import React from "react";
import Modal, { CloseModalButton } from "app/components/Modal";
import {
  BillingPeriodUnit,
  ChangePlanType,
  Plan,
  SubscriptionStatus,
} from "services/graphql";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import moment from "moment";
import { centsToDollars } from "helpers/centsToDollars";
import { AbbreviatedPeriodUnits, PeriodUnitAdverbs } from "constants/cart";
import { ArrowDown } from "app/components/Icon";
import Button from "app/components/Button";
import { Routes } from "constants/routes";
import { useHistory } from "react-router";
import LoaderOverlay from "app/components/Loader/LoaderOverlay";
import styled from "styled-components";
import { useIsSmallScreen } from "hooks/index";
import styles from "./styles.module.scss";
import { useSubscriptionSwitchPlan } from "../../hooks";

const MobileModal = styled(Modal)`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  border-radius: 0;
  overflow: auto;
`;

interface Props {
  plan: Plan;
  closeModal(): void;
}

export const CHANGE_PLAN_TYPE_VERB = {
  [ChangePlanType.Upgrade]: "upgrading",
  [ChangePlanType.Downgrade]: "downgrading",
  [ChangePlanType.BillingPeriod]: "switching",
  [ChangePlanType.None]: "",
};

export const CHANGE_PLAN_TYPE_ACTION = {
  [ChangePlanType.Upgrade]: "Upgrade",
  [ChangePlanType.Downgrade]: "Downgrade",
  [ChangePlanType.BillingPeriod]: "Switch",
  [ChangePlanType.None]: "",
};

export function ConfirmPlanModal({ plan, closeModal }: Props) {
  const history = useHistory();
  const isSmallScreen = useIsSmallScreen();
  const {
    primarySubscription,
    loadingPrimarySubscription,
  } = usePrimarySubscription();
  const {
    subscriptionSwitchPlan,
    subscriptionSwitchPlanLoading,
  } = useSubscriptionSwitchPlan();

  if (!plan) {
    return null;
  }

  if (!primarySubscription) {
    return null;
  }

  const isTrialing = primarySubscription.status === SubscriptionStatus.InTrial;
  const { canTrial, changePlanEstimate } = plan;
  const { changePlanType, isEndOfTerm, invoiceEstimate } =
    changePlanEstimate || {};
  let amountDueText = "";

  if (!invoiceEstimate) {
    return null;
  }

  const amountDueAfterPeriodInDollars = invoiceEstimate.total / 100;
  const amountDueTodayInDollars = invoiceEstimate.amountDue / 100;
  const amountDueToday =
    isTrialing || isEndOfTerm ? 0 : amountDueTodayInDollars;
  const currentPlan = primarySubscription.plan;

  if (canTrial && isTrialing) {
    amountDueText = `after trial (${moment(
      primarySubscription.trialEndTime
    ).format("MM/DD/YY")})`;
  } else if (isEndOfTerm) {
    amountDueText = `after billing period ends (${moment(
      primarySubscription.currentTermEnd
    ).format("MM/DD/YY")})`;
  } else {
    amountDueText = "next billing period";
  }

  const handleSwitchPlan = async () => {
    await subscriptionSwitchPlan({
      subscriptionId: primarySubscription.id,
      planId: plan.id,
    });
    history.push(Routes.account__subscription);
    closeModal();
  };

  const isDataLoading =
    loadingPrimarySubscription || subscriptionSwitchPlanLoading;

  const RenderedModal = isSmallScreen ? MobileModal : Modal;

  return (
    <>
      <RenderedModal
        isOpen
        onBackgroundClick={closeModal}
        onEscapeKeydown={closeModal}
      >
        <div className={styles.modalContainer}>
          <div className={styles.headerContainer}>
            <h1>{CHANGE_PLAN_TYPE_ACTION[changePlanType]} Plan</h1>
            <CloseModalButton onClick={closeModal} variant="light" />
          </div>
          <div className={styles.plansContainer}>
            <div className={styles.toggledPlanCardContainer}>
              <PlanCardDetails
                plan={currentPlan}
                header={`Current Plan: ${currentPlan.accessType}`}
              />
            </div>
            <ArrowDown width="20px" />
            <div className={styles.planCardContainer}>
              <PlanCardDetails
                plan={plan}
                header={`New Plan: ${plan.accessType}`}
              />
            </div>
          </div>
          <div className={styles.amountDueContainer}>
            <p>Amount due {amountDueText}</p>
            <strong>${amountDueAfterPeriodInDollars}</strong>
          </div>
          <div className={styles.totalsContainer}>
            <p>Today&apos;s total</p>
            <strong>${amountDueToday}</strong>
          </div>
          <span className={styles.spanContainer}>
            You are {CHANGE_PLAN_TYPE_VERB[changePlanType]} to the{" "}
            <strong>
              {plan.accessType}{" "}
              {
                PeriodUnitAdverbs[
                  plan?.periodUnit as keyof typeof PeriodUnitAdverbs
                ]
              }{" "}
            </strong>
            plan.
            {isEndOfTerm
              ? ` Your plan will switch after the end your current billing term: ${moment(
                  primarySubscription.currentTermEnd
                ).format("MM/DD/YY")}`
              : " This change will be effective immediately."}
          </span>
          <br />
          {!isEndOfTerm && (
            <span className={styles.spanContainer}>
              Your total payment of <strong>${amountDueToday}</strong> will be
              charged immediately.
            </span>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              closeModal();
            }}
            disabled={isDataLoading}
            className={styles.cancelButton}
          >
            Nevermind
          </Button>
          <Button
            onClick={() => {
              handleSwitchPlan();
            }}
            className={styles.confirmButton}
            disabled={isDataLoading}
          >
            <>{CHANGE_PLAN_TYPE_ACTION[changePlanType]} Plan</>
          </Button>
        </div>
      </RenderedModal>
      {isDataLoading && <LoaderOverlay />}
    </>
  );
}

function PlanCardDetails({ plan, header }: { plan: Plan; header: string }) {
  return (
    <>
      <div className={styles.planCardHeaderContainer}>
        <header>{header}</header>
      </div>
      <div className={styles.planCardSubContainer}>
        <div>
          <div className={styles.planCardCostContainer}>
            <h2>${centsToDollars(plan.monthlyCostInCents)}/mo</h2>
            {plan?.periodUnit !== BillingPeriodUnit.Month && (
              <p>
                (Billed ${centsToDollars(plan.priceInCents)}/
                {
                  AbbreviatedPeriodUnits[
                    plan?.periodUnit as keyof typeof AbbreviatedPeriodUnits
                  ]
                }
                )
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
